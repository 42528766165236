<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../../assets/textures/naranja.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">MI TIENDA</h2>
      <p class="main__header--subtitle">OBRAS ORIGINALES</p>
    </div>
    <div class="main__gallery">
      <div class="gallery__content" >
        <router-link v-for="(item, index) in pageOfItems" :key="index" :to="item.ruta">
          <img class="gallery__content--img" :src="item.image">
        </router-link>
      </div>
    </div>
    <div class="main__pagination">
      <a href="#top">
        <jw-pagination :items="items" @changePage="onChangePage"></jw-pagination>
      </a>
    </div>
    <div class="main__footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../components/Footer.vue';

/** MOCKUPS */
import AzuMo from '../../assets/images/Tienda/Azulado/1.jpg';
import BarMo from '../../assets/images/Tienda/Barco/1.jpg';
import CalMo from '../../assets/images/Tienda/Calma/1.jpg';
import CarMo from '../../assets/images/Tienda/Cardiaco/1.jpg';
import DesMo from '../../assets/images/Tienda/Despierta/1.jpg';
import DevMo from '../../assets/images/Tienda/Desvanece/1.jpg';
import DetMo from '../../assets/images/Tienda/Detras/1.jpg';
import EncMo from '../../assets/images/Tienda/Encuentro/1.jpg';
import ManMo from '../../assets/images/Tienda/Mandala/1.jpg';
import MarMo from '../../assets/images/Tienda/Mar/1.jpg';
import SurMo from '../../assets/images/Tienda/Surrealista/1.jpg';
import TroMo from '../../assets/images/Tienda/Trompas/1.jpg';
import UniMo from '../../assets/images/Tienda/Universal/1.jpg';
import VieMo from '../../assets/images/Tienda/Viento/1.jpg';

export default {
    data () {
      return {
        items: [
          {
            image: AzuMo,
            ruta: '/store/azulado'
          },       
          {
            image: BarMo,
            ruta: '/store/barco'
          },
          {
            image: CalMo,
            ruta: '/store/calma'
          },
          {
            image: CarMo,
            ruta: '/store/cardiaco'
          },
          {
            image: DesMo,
            ruta: '/store/despierta'
          },
          {
            image: DevMo,
            ruta: '/store/desvanece'
          },
          {
            image: DetMo,
            ruta: '/store/detras'
          },
          {
            image: EncMo,
            ruta: '/store/encuentro'
          },
          {
            image: ManMo,
            ruta: '/store/mandala'
          },
          {
            image: MarMo,
            ruta: '/store/mar'
          },
          {
            image: SurMo,
            ruta: '/store/surrealista'
          },
          {
            image: TroMo,
            ruta: '/store/trompas'
          },
          {
            image: UniMo,
            ruta: '/store/universal'
          },
          {
            image: VieMo,
            ruta: '/store/viento'
          },   
        ],
        pageOfItems: [],
        index: null
      }
    },
    methods: {
      onChangePage(pageOfItems) {
        this.pageOfItems = pageOfItems;
      }
    },
    components: {
      Footer
    } 
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--bg-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 80px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__pagination{
  margin-top: 50px;
  text-align: center;
}
.main__gallery{
  margin-top: -100px;
}
.gallery__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.gallery__content--img{
  width: 500px;
  height: 500px;
  object-fit: cover;
  margin: 20px;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 40px;
  }
  .gallery__content--img {
    width: 200px;
    height: 200px;
  }
  .info--price {
    font-size: 30px;
  }
}
</style>